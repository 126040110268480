<template>
  <ion-content class="default-padding">
    <ion-list>
      <ion-item>
        <ion-label position="stacked">
          {{ $t('name') }}*
        </ion-label>
        <ion-input type="text" :placeholder="$t('name')" :value="create.name" @ionInput="create.name = $event.target.value" />
      </ion-item>

      <ion-item>
        <ion-label position="stacked">
          {{ $t('chooseatype') }}*
        </ion-label>
        <ion-select :placeholder="$t('choosetype')" :value="create.type" @ionChange="create.type = $event.target.value">
          <ion-select-option value="CLUB">
            {{ $t('club') }}
          </ion-select-option>
          <ion-select-option value="SHOP">
            {{ $t('shop') }}
          </ion-select-option>
          <ion-select-option value="TRAINER">
            {{ $t('trainer') }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <div v-if="!model">
        <ion-item>
          <ion-label position="stacked">
            {{ $t('website') }}
          </ion-label>
          <ion-input type="text" placeholder="https://motoinside.app" :value="create.website" @ionInput="create.website = $event.target.value" />
        </ion-item>

        <ion-item>
          <ion-label position="stacked">
            {{ $t('email') }}
          </ion-label>
          <ion-input type="text" :placeholder="CONTACT_EMAIL" :value="create.email" @ionInput="create.email = $event.target.value" />
        </ion-item>

        <ion-item>
          <ion-label position="stacked">
            {{ $t('phone') }}
          </ion-label>
          <ion-input type="tel" :value="create.phone" @ionInput="create.phone = $event.target.value" />
        </ion-item>

      </div>

      <ion-item v-if="!model" button @click="openPlaceModal()">
        <ion-label>
          <span class="block text-sm mb-2">{{ $t('location') }}*</span>
          <span v-if="location && location.name">{{ location.name }}</span>
        </ion-label>
      </ion-item>

      <ion-input ref="picture" type="file" accept="image/*" style="display:none;" @ionInput="fileUpload($event)" />

      <ion-item class="flex justify-between">
        <ion-label style="pointer-events: all !important" @click="openFile">
          <div class="w-16 h-16 rounded-full border border-gray-500 bg-cover" :style="{ backgroundImage: `url(${image})` }" />
        </ion-label>
        <ion-button expand="full" @click="openFile">
          {{ $t('chooseaprofilepicture') }}
        </ion-button>
      </ion-item>
    </ion-list>

    <ion-grid v-if="!model">
      <ion-row>
        <ion-col>
          <p class="text-sm">
            {{ $t('stripeverificationmessage') }}
          </p>
        </ion-col>
      </ion-row>
    </ion-grid>

    <Error :error="error" />
    <LoadingButton :disabled="checkIfAllFilledIn() || loading" :loading="loading" @click="$emit('submit', $data)">
      {{ model ? $t('update') : $t('create') }}
    </LoadingButton>
  </ion-content>
</template>

<script>
import Modal from '@/components/modals/CreatePlaceModal.vue'
import { mapGetters } from "vuex";
import { GET_CIRCUIT_PLACE_GETTER } from "@/store/store-getters";
import { APP_DEFAULTS } from "@/configs";

export default {
  props: {
    loading: {
      required: true,
      type: Boolean,
    },
    error: {
      type: [ Error ],
      default: null
    },
    model: {
      default: null,
      required: false,
      type: Object
    }
  },
  data() {
    return {
      image: this.model?.profile_image ? `${this.$apiStorageUrl}/${this.model.profile_image}` : '',
      file: null,
      kvk: '',
      location: '',
      CONTACT_EMAIL: APP_DEFAULTS.CONTACT_EMAIL,
      create: {
        name: this.model?.name || '',
        website: '',
        email: '',
        phone: '',
        location: '',
        type: this.model?.facility_type?.name || '',
      }
    }
  },
  computed: {
    ...mapGetters([GET_CIRCUIT_PLACE_GETTER]),
  },
  mounted() {
    this.$on('picked-location', (location) => {
      this.location = location;
    })
  },
  methods: {
    openFile() {
      this.$refs.picture.getElementsByTagName('input')[0].click()
    },
    fileUpload(event) {
      this.file = event.target.getElementsByTagName('input')[0].files[0];

      this.image = this.file ? URL.createObjectURL(this.file) : undefined;
      this.$forceUpdate();
    },
    openPlaceModal() {
      return this.$ionic.modalController
          .create({
            component: Modal,
            componentProps: {
              parent: this,
            },
          })
          .then(m => m.present())
    },
    checkIfAllFilledIn() {
      if(!this.create.name) {
        return true;
      }

      if(!this.location?.name && !this.model) {
        return true;
      }

      if(!this.create.type) {
        return true;
      }

      if(!this.image) {
        return true;
      }

      return false
    }
  }
}
</script>
