<template>
  <ion-page class="ion-page">
    <GoBackHeader :default-href="`/facilities/${id}`" />

    <facility-form
      v-if="facility"
      :model="facility"
      :loading="loading"
      :error="error"
      :disabled="disabled"
      @submit="update"
    />
  </ion-page>
</template>

<script>
import FacilityForm from "./form/FacilityForm";
import {GET_FACILITY} from "@/graphql/queries";
import {UPDATE_FACILITY} from "@/graphql/mutations";

export default {
  components: {
    FacilityForm,
  },
  props: {
    id: {
      required: true,
      type: String,
    }
  },
  data() {
    return {
      loading: false,
      error: null,
      disabled: false,
    }
  },
  apollo: {
    facility: {
      query: GET_FACILITY,
      fetchPolicy: 'network-only',
      variables() {
        return {
          id: this.$route.params.id
        }
      }
    }
  },
  methods: {
    async update(data) {
      this.loading = true;

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_FACILITY,
          variables: {
            id: this.$route.params.id,
            input: {
              name: data.create.name,
              type: data.create.type,
              profile_image: data.file,
            }
          }
        });

        this.$router.push({ name: this.$routeNames.FACILITY, params: { id: this.$route.params.id }})
      } catch (error) {
        this.error = error;
      }

      this.loading = false;
    },
  }
}

</script>
